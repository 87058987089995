import React, { Fragment, useEffect, useState } from 'react';
import { getAllApplicationsData } from '../../../services/applications.service';
import { useSelector } from 'react-redux';

// chakra
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Button,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
	Flex,
	Container,
	Stack,
	Text,
	Input,
	StackDivider,
	Image,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const Landlords = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [landlordApplications, setLandlordApplications] = useState([]);

	const { landlords } = useSelector((state) => state.user);

	useEffect(() => {
		const fetchData = async () => {
			setLandlordApplications(landlords);
		};

		fetchData().catch(console.error);
	}, [landlords]);

	return (
		<Fragment>
			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<Box
					bg="white"
					rounded="lg"
					p="4"
				>
					<TableContainer>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>#</Th>
									<Th>Email</Th>
									<Th>First Name</Th>
									<Th>Last Name</Th>
									<Th>Phone Number</Th>
									<Th>Status</Th>
									<Th>Time Completed</Th>
									<Th>Properties Uploaded</Th>
								</Tr>
							</Thead>

							<Tbody>
								{landlords?.map((user, index) => (
									<Tr key={index}>
										<Td>{index + 1}</Td>
										<Td>{user?.userData?.email}</Td>
										<Td>
											{user?.userData?.application
												?.stepOne?.[0]?.answer || '-'}
										</Td>
										<Td>
											{user?.userData?.application
												?.stepOne?.[1]?.answer || '-'}
										</Td>
										<Td>
											{user?.userData?.application
												?.stepOne?.[2]?.answer || '-'}
										</Td>
										<Td>
											{user?.userData?.application
												?.applicationCompleted
												? 'Completed'
												: user?.userData?.application
														?.applicationCompleted
												? 'Started'
												: 'Not Started'}
										</Td>
										<Td>
											{
												user?.userData?.application
													?.applicationCompletedTime
											}
										</Td>
										<Td>
											{user?.properties
												? user?.properties?.length
												: 0}
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>

			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size="full"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit Property</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack
							spacing="6"
							divider={<StackDivider borderColor="gray.200" />}
						>
							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 1:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Property Type</Text>
										<Input value="Single-family Home" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Bedrooms</Text>
										<Input value="3" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Baths</Text>
										<Input value="4" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Monthly Rent</Text>
										<Input value="1000" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Square Feet</Text>
										<Input value="1000" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Year Built</Text>
										<Input value="2002" />
									</Box>
								</Stack>
							</Box>

							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 2:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">State</Text>
										<Input value="Texas" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Metro</Text>
										<Input value="Dallas" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">City</Text>
										<Input value="" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">
											Property Address or Zillow/Redfin
											Link
										</Text>
										<Input value="116 Collin Court, Murphy, TX, 75094" />
									</Box>
								</Stack>
							</Box>

							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 3:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Value of Property</Text>
										<Input value="200000" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Reason for Value</Text>
										<Input value="This is the value of the property." />
									</Box>
								</Stack>
							</Box>

							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 4:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Property Description</Text>
										<Input value="This is the description of the property." />
									</Box>
								</Stack>
							</Box>

							<Box>
								<Text
									fontWeight="600"
									fontSize="lg"
									mb="2"
								>
									Step 5:
								</Text>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Sales CMA</Text>
										<Input value="No uploaded files" />
									</Box>

									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Appraisal Report</Text>
										<Input value="No uploaded files" />
									</Box>
								</Stack>

								<Stack spacing="3">
									<Box
										bg="gray.200"
										p="4"
										rounded="lg"
									>
										<Text mb="2">Property Images</Text>
										<Stack spacing="3">
											<Flex align="center">
												<Image
													src="https://www.redfin.com/blog/wp-content/uploads/2021/06/NYC3.jpg"
													alt="property"
													maxW="100px"
													mr="4"
												/>
												<Text>Living room</Text>
											</Flex>

											<Flex align="center">
												<Image
													src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhdDm_iYl5yccPMMhUgLldFtdG1tulY_JS3Q&s"
													alt="property"
													maxW="100px"
													mr="4"
												/>
												<Text>Kitchen</Text>
											</Flex>
										</Stack>
									</Box>
								</Stack>
							</Box>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="red"
							mr={3}
							onClick={onClose}
						>
							Close
						</Button>
						<Button
							colorScheme="blue"
							onClick={onClose}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Fragment>
	);
};

export default Landlords;
